<template>
  <div class="exhibitions">
    <div class="exhibitions__wrapper">
      <div
        class="exhibitions__background"
        :style="{
          backgroundImage: `url(${featuredExhibition.header_image})`,
        }"
      ></div>
      <div class="exhibitions__content">
        <div class="exhibitions__details-box">
          <span class="exhibitions__details-box_data">
            {{ featuredExhibition.dates }} | {{ featuredExhibition.exhibition_type }}
          </span>
          <h2 class="exhibitions__details-box_title">{{ featuredExhibition.title }}</h2>
          <div
            class="exhibitions__details-box_details collect-editor collect-editor--white"
            v-html="featuredExhibition.home_description"
          ></div>
          <BaseButton
            class="button button--public button--public-bold button--white"
            text="MORE INFORMATION"
            @click="$router.push({ path: `/exhibitions/${featuredExhibition.slug}` })"
          />
        </div>

        <div class="exhibitions__title-box">
          <h2 class="exhibitions__title-box_title">Exhibitions</h2>
          <div class="exhibitions__title-box_right">
            <div class="exhibitions__title-box_right-content">
              <Tag
                class="tag--public"
                :class="{ 'tag--selected': i.selected }"
                v-for="i in tagsData"
                :key="i.id"
                :value="i.title"
                @click="changeFilterValue(i)"
              />
            </div>
            <div class="divider divider--vertical"></div>
            <div class="exhibitions__title-box_right-content">
              <PublicSelect
                style="min-width: 240px"
                :items="sortData"
                :selected="cardData.sort"
                @input="selectSort"
              ></PublicSelect>
            </div>
          </div>
        </div>

        <div v-if="exhibitions.length" class="exhibitions__exhibitions-box">
          <router-link
            v-for="(item, key) in exhibitions"
            :key="`${key}-item`"
            :to="{ path: `/exhibitions/${item.slug}` }"
            v-slot="{ navigate }"
            custom
          >
            <div class="exhibitions__exhibitions-box_card" @click="navigate">
              <div class="image-box">
                <img class="image" :src="item.search_image" />
                <div class="hover-container">Click to View</div>
              </div>
              <div class="type">{{ item.type }}</div>
              <h3 class="title">{{ item.title }}</h3>
              <span class="data">{{ item.dates }}</span>
            </div>
          </router-link>
        </div>
        <h6 class="exhibitions__empty" v-else>No exhibitions</h6>

        <div class="exhibitions__button-box" v-if="hasMorePages">
          <BaseButton
            class="button button--xs button--white"
            text="LOAD MORE"
            @click.native="loadMore"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PublicSelect from "@/components/inputs/PublicSelect.vue";
import Tag from "@/components/Tag.vue";

export default {
  components: { BaseButton, PublicSelect, Tag },

  data() {
    return {
      cardData: {
        type: "",
        sort: "newest_to_oldest",
        page: 1,
      },
      filterBlock: {
        list: "types",
        type: "collection",
      },
      sortData: [
        { id: "newest_to_oldest", title: "Newest to Oldest" },
        { id: "oldest_to_newest", title: "Oldest to Newest" },
      ],
    };
  },
  head: {
    title: function () {
      return {
        inner: "Exhibitions browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Exhibitions browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Exhibitions browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Exhibitions browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Exhibitions browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Exhibitions browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Exhibitions browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Exhibitions browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Exhibitions browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    await this.apiGetFeaturedExhibition();
    await this.apiGetExhibitions(this.cardData);
    await this.apiGetFiltersBlock(this.filterBlock);
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Exhibitions",
        to: "/exhibitions",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },
  watch: {
    tagsData: {
      handler: function (val, oldVal) {
        let type = "";
        val.forEach(el => {
          if (el.selected) {
            type = el.id === "" ? "" : el.id;
          }
        });

        this.cardData.type = type;
        this.selectTypes(type);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("publicapi/exhibitions", {
      exhibitions: "getExhibitions",
      hasMorePages: "getHasMorePages",
      featuredExhibition: "getFeaturedExhibition",
      tagsData: "getFilters",
    }),
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapMutations("publicapi/exhibitions", ["changeFilterValue"]),
    ...mapActions("publicapi/exhibitions", [
      "apiGetExhibitions",
      "apiGetFiltersBlock",
      "apiGetFeaturedExhibition",
    ]),
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.cardData, page: this.cardData.page + 1 };
        let success = await this.apiGetExhibitions(data);
        if (success) {
          this.cardData = data;
        }
      }
    },
    async selectTypes(types) {
      let data = { ...this.cardData, page: 1, type: types };
      let success = await this.apiGetExhibitions(data);
      if (success) {
        this.cardData = data;
      }
    },
    async selectSort({ id }) {
      let data = { ...this.cardData, page: 1, sort: id };
      let success = await this.apiGetExhibitions(data);
      if (success) {
        this.cardData = { ...data };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media screen and (max-width: $xs) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

.exhibitions {
  display: flex;

  &__wrapper {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58.4rem;
    z-index: 0;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $xs) {
      height: 33.5rem;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: $lightBlack, $alpha: 0.45);
      z-index: 1;
    }
    &::after {
      content: "";
      bottom: -1px;
      position: absolute;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        0deg,
        rgba($color: $lightBlack, $alpha: 1) 0%,
        rgba($color: $lightBlack, $alpha: 0) 100%
      );
      z-index: 1;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40rem;
    padding: 0rem 13.7rem 7.4rem;
    z-index: 2;
    @media screen and (max-width: $md) {
      margin-top: 24.5rem;
      padding: 0rem 8rem 7.4rem;
    }
    @media screen and (max-width: $sm) {
      padding: 0rem 3rem 7.4rem;
    }
    @media screen and (max-width: $xs) {
      padding: 0rem 25px 11rem;
    }
  }

  &__details-box {
    display: flex;
    flex-direction: column;
    width: 55%;
    @media screen and (max-width: $md) {
      width: 100%;
    }

    &_data {
      font-size: 1.8rem;
      line-height: 1.5;
      text-transform: uppercase;
      @media screen and (max-width: $xs) {
        font-size: 14px;
      }
    }

    &_title {
      font-size: 5.2rem;
      line-height: 1.55;
      margin-bottom: 30px;
      @media screen and (max-width: $md) {
        font-size: 35px;
        margin-bottom: 20px;
      }
    }

    .button {
      margin-top: 2.2rem;
      @media screen and (max-width: $xs) {
        margin-top: 3.5rem;
      }
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 9rem 0rem 6rem;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: $xs) {
      margin: 8.5rem 0rem 2rem;
    }
    &_right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media screen and (max-width: $md) {
        width: 100%;
        flex-direction: column;
        .divider {
          display: none;
        }
        &-content {
          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
      &-content {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & > * {
          margin-bottom: 10px;
        }
      }
      @media screen and (max-width: $lg) {
        margin-top: 30px;
        margin-left: -10px;
      }
      @media screen and (max-width: $xs) {
        &-content {
          flex-wrap: wrap;
        }
      }
    }
    &_title {
      font-size: 5.2rem;
      line-height: 1.25;
      @media screen and (max-width: $md) {
        font-size: 35px;
      }
    }
  }
  &__empty {
    font-size: 20px;
    font-weight: 600;
    padding: 50px 10px;
    text-align: center;
    text-transform: uppercase;
  }

  &__exhibitions-box {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3.9rem;
    grid-row-gap: 3.9rem;
    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: 1fr;
      grid-column-gap: 25px;
      grid-row-gap: 25px;
    }

    &_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }

      .image-box {
        position: relative;

        .image {
          height: 27vw;
          width: 100%;
          object-fit: cover;
          background-size: cover;
          background-position: center;
          border-radius: 4px;
          @media screen and (max-width: $md) {
            height: 40vw;
          }
          @media screen and (max-width: $xs) {
            height: 90vw;
          }
        }

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
          border-radius: 4px;
        }
      }

      .type {
        display: flex;
        justify-content: center;
        align-content: center;
        text-transform: uppercase;
        border: 1px solid $white;
        border-radius: 4px;
        width: max-content;
        min-width: 9.7rem;
        margin-top: 2.6rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
        padding: 0.1rem 1rem;
      }

      .title {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1rem;
        //cursor: pointer;
      }

      .data {
        margin-top: 1.2rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
        text-transform: uppercase;
        @media screen and (max-width: $xs) {
          margin-top: 1rem;
          // font-size: 1.4rem;
          // line-height: 2.5rem;
        }
      }
    }
  }

  &__button-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 7rem;

    .button {
      min-width: 180px;
    }
  }
}
</style>

